import { createEntityAdapter, EntityAdapter } from '@locumsnest/core/src/lib/ngrx/cached-state';
import { IGradeRateEntity } from '../../interfaces/api/grade-rate-entity';

export function sortByName(a: IGradeRateEntity, b: IGradeRateEntity): number {
  return a.grade - b.grade;
}

export const adapter: EntityAdapter<IGradeRateEntity> = createEntityAdapter<IGradeRateEntity>({
  sortComparer: sortByName,
});

export const initialState = adapter.getInitialState({
  // additional entity state properties
  selectedGradeRateId: null,
});
